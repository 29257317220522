import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/scss/index.scss'
import Vant from 'vant';
import 'vant/lib/index.css';
import httpRequest from '@/utils/httpRequest' // api: https://github.com/axios/axios
import VueCookies from 'vue-cookies'
import api from './utils/api'
import ElementUI from 'element-ui';                      // 引入element-ui
import 'element-ui/lib/theme-chalk/index.css';           // element-ui的css样式要单独引入
Vue.use(ElementUI);
// import EXIF from 'exif-js'
// Vue.prototype.EXIF = EXIF
Vue.use(VueCookies)

import httpNew from "@/utils/request-new"
Vue.prototype.$axios = httpNew

import moment from "moment"
Vue.prototype.$moment = moment;

// 高德离线地图
// import VueAMap from 'vue-amap';
// Vue.use(VueAMap);
// 初始化vue-amap
// VueAMap.initAMapApiLoader({
//   // 高德key
//   key: '34ce1d182accab3617699fbf2f28d72a',
//   // 插件集合 （插件按需引入）
//   plugin: ['AMap.Geocoder', 'AMap.Geolocation', 'AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor']
// })
// import ResizeObserver from 'resize-observer-polyfill'
// window.ResizeObserver = ResizeObserver// 挂载到window上，因为插件内是使用的new语法 会直接在window上找
// import Vconsole from 'vconsole';
// setTimeout(() => {
//   const vConsole = new Vconsole()
//   Vue.use(vConsole)
// }, 2000)
// if (process.env.NODE_ENV == 'development') {
//   const vConsole = new Vconsole()
//   Vue.use(vConsole)
// }
Vue.prototype.$roomId = 'mkMD1LVuGaU=';
Vue.prototype.$baseURL = '';
Vue.prototype.$globalData = {
  userInfo: null,
  fileSize: 1024 * 100 * (1024),//上传文件的限制大小单位b
  token: '',
}
Vue.prototype.$http = httpRequest // ajax请求方法
Vue.prototype.$api = api //api
Vue.use(Vant);
Vue.config.productionTip = false
console.log(process.env);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
