import axios from "axios";
const requestHttp = (query) => {
    if (!query.data.tokenId) {
        let tokenInfo = webApi.getHxjiotToken();
        // console.log("tokenInfo", tokenInfo)
        let tokenId = JSON.parse(tokenInfo).tokenId;
        query.data.tokenId = tokenId;
    }
    return new Promise((resolve, reject) => {
        axios({
            url: `http://t.server.hxjiot.com`,
            method: 'post',
            header: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
            responseType: 'JSON',
            data: query.data
        }).then(({ data: res }) => {
            resolve(res)
        }).catch((error) => {
            reject(error)
        })
    })
}
export default requestHttp