export default {
     //标准体系
     standardList: (params) => { return `/standard/list` },//根据体系id获取标准类型树
     standardListByStandardType: (typeId) => { return `/standard/listByStandardType/${typeId}` },//根据类型获取所有标准,并整理成指定结构
     standardListByStandardTypeSelect: (typeId) => { return `/standard/listByStandardType/${typeId}/select` },//根据类型获取所有标准
     standardSystem: (typeId) => { return `/standardSystem/select` },//取所有标准体系
     standardSystemListByUnitSelect: (unitId) => { return `/standardSystem/listByUnit/${unitId}/select` },//取所该点位关联的所有标准体系
     standardTemplateListByStandard: (id) => { return `/standardTemplate/listByStandard/${id}` },//根据standardId取所有标准模板
     standardTypeListBySystem: (systemId) => { return `/standardType/listBySystem/${systemId}/select` },//获取所有标准类型
     unitGetStandardType: (unitId) => { return `/unit/${unitId}/getStandardType` },//根据点位获取关联标准类型
     //点位
     unitListByGridIdSelect: (gridId) => { return `/unit/listByGridId/${gridId}/select` },//根据网格id获取点位
     unitListByNear: (params) => { return `/unit/listByNear` },//附近点位
     //网格
     gridListBySystemSelect: (systemId) => { return `/grid/listBySystem/${systemId}/select` },//获取所有网格SelectVo
     //认证
     authLogin: (params) => { return `/auth/login` },//浙政钉登录
     authLoginByAuthCode: (authCode) => { return `/auth/loginByAuthCode/${authCode}` },//浙政钉登录
     
     //部门
     departmentListSelect: (params) => { return `/department/list/select` },//获取所有责任单位
     //任务管理
     taskDetail: (id) => { return `/task/${id}` },//获取任务详情
     taskList: (params) => { return `/task/list` },//获取任务列表
     taskUnitList: (taskId) => { return `/task/${taskId}/unit/list` },//获取任务下的点位
     taskUnit: (taskId, unitId) => { return `/task/${taskId}/unit/${unitId}` },//获取任务下的点位
     taskUnitCheckUpOver: (taskId, unitId) => { return `/task/${taskId}/unit/${unitId}/checkUpOver` },//检测结束

     //问题
     problemApplyDelay: (problemId) => { return `/problem/${problemId}/applyDelay` },//申请延期
     problemUploadRectificationResult: (problemId) => { return `/problem/${problemId}/uploadRectificationResult` },//整改反馈
     problemDetail: (problemId) => { return `/problem/detail/${problemId}` },//问题详情
     problemList: (params) => { return `/problem/list` },//问题列表
     problemUpload: (params) => { return `/problem/upload` },//上报问题
     problemAllWorkFlows: (problemId) => { return `/problem/${problemId}/allWorkFlows` },//指定问题的所有工作流
     // problemGetPhotosByProblemStatus: (problemId, problemStatusId) => { return `/problem/${problemId}/getPhotosByProblemStatus/${problemStatusId}` },//根据problemId获取指定工作流的照片
     editProblem: (problemId) => { return `/problem/${problemId}/editProblem` },//修改问题
     
     //整改反馈
     feedbackTemplateListByStandardId: (standardId) => { return `/feedback/template/listByStandardId/${standardId}` },//获取反馈模板列表
     
     //首页
     indexMyPending: (params) => { return `/index/myPending` },//获取我的代办任务
     //其他
     fileUpload: () => { return `/file/upload` },//上传文件
     fileDownload: (objectName,token) => { return `/file/download?objectName=${objectName}&token=${token}` },//获取图片路径
     staffUpdatePw: () => { return `/staff/updatePw` },//修改密码
     getJsApiToken: () => { return `/system/getJsApiToken` },//获取浙政钉JsApiToken
     authLogout: () => { return `/auth/logout` },//退出登录
     
     //随手拍
     standardList: () => { return `/standard/list` },//随手拍_获取标准列表
     standardTemplateGetByStandardId: (standardId) => { return `/standard/template/getByStandardId/${standardId}` },//获取模板
     departmentGetByGps: () => { return `/department/getByGps` },//随手拍_根据gps获取责任部门
     problemUpload: () => { return `/problem/upload` },//上报随手拍
     problemList: () => { return `/problem/list` },//问题列表
     problemGetPhotosByProblemStatus: (problemId,problemStatusId) => { return `/problem/${problemId}/getPhotosByProblemStatus/${problemStatusId}` },//根据problemId获取指定工作流的照片
}