import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import qs from 'qs'
import merge from 'lodash/merge'
import {
  clearLoginInfo
} from '@/utils'
import {
  Toast
} from 'vant'
const http = axios.create({
  timeout: 1000 * 30,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8 ',
  }
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
  // console.log("webApi", webApi)
  //console.log("config",config)

  config.headers['Authorization'] = 'Bearer ' + JSON.parse(webApi.getToken()).access_token

  // config.headers['Authorization'] = 'Bearer ' + ('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJhOWZmMmRkNC0wMDJkLTlkY2YtZGJmNC03ZjczYTAwYjgxN2YiLCJpc3MiOiJodHRwczovLyoueXNscG1zLmNvbS8iLCJpYXQiOjE2ODA1MTA0NjMsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWVpZGVudGlmaWVyIjoiMTM0IiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZSI6Iua1i-ivlei0puaIty3oh6rliqnmnLoiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3NlcmlhbG51bWJlciI6IjhkMzhlYmE2NTk4NDQwZWZhNzgzNzRiZDQ1ZjNjMzZhIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy91c2VyZGF0YSI6IjEzNCIsIkhvdGVsIjoieWRwbXMiLCJEaXNwbGF5TmFtZSI6Iua1i-ivlei0puaIty3oh6rliqnmnLoiLCJHIjoi566h55CG5ZGYIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjpbIkFyIiwiQnIiLCJDRiIsIkNvbG9yQ29uZmlnIiwiSEMiLCJIb21lQ2VudGVyIiwiUmVjb3JkIiwiUmVwb3J0IiwiUmV2ZXJzZSIsIlN0b2NrIiwiU3lzdGVtIiwiVSIsIlZpcCJdLCJuYmYiOjE2ODA1MTA0NjMsImV4cCI6MTY4MDU1MzY2MywiYXVkIjoiQW55In0.dDDQ1_4TxBU4SUrZbo7L-QXZtDwSWS3Klm5XvXmAP54') // 请求头带上token
  // if(config.)
  // 请求头带上token
  // 'Bearer ' + !webApi ? webApi.getToken() :
  // config.headers['token'] = Vue.$cookies.get('token') 

  return config
}, error => {
  return Promise.reject(error)
})

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
  if (response.data) {
    switch (response.data.Code) {
      case 1000:
        break
      case 400:
        Toast.fail("操作失败！发送的请求中参数有误。")
        break
      case 401:
        // clearLoginInfo()
        // Toast.fail("操作失败！您的登录状态已经过期，请重新登录。")
        // router.replace({
        //   name: 'index'
        // })
        break
      case 502:
        Toast.fail("操作失败！您无权进行该操作。")
        break
      case 504:
        Toast.fail("操作失败！由于该对象正被其他关联数据使用，无法删除。")
        break;
      default:
      // Toast.fail(response.data.msg)
    }
  }
  return response
}, error => {
  console.log(error)
  // Toast.fail("操作失败！无法连接到服务，请检查您的网络连接。");
  return Promise.reject(error)
})

/**
 * 请求地址根路径处理
 * @param {*} actionName action方法名称
 */
http.changeUrl = (actionName) => {
  Vue.$baseURL = actionName || process.env.VUE_APP_BASE_URL
}

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
http.adornUrl = (actionName) => {
  if (actionName.indexOf("http") == 0) {
    return actionName
  }
  // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
  // return (process.env.NODE_ENV !== 'production' && process.env.OPEN_PROXY ? '/proxyApi/' : process.env.VUE_APP_BASE_URL) + actionName
  let URL = process.env.VUE_APP_BASE_URL
  if (Vue.$baseURL) {
    URL = Vue.$baseURL
  }
  return window.SITE_CONFIG['apiURL'] + actionName
}

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
http.adornParams = (params = {}, openDefultParams = true) => {
  var defaults = {
    't': new Date().getTime()
  }
  return openDefultParams ? merge(defaults, params) : params
}

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}, openDefultdata = true, contentType = 'json') => {
  var defaults = {
    't': new Date().getTime()
  }
  data = openDefultdata ? merge(defaults, data) : data
  return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data)
}

export default http