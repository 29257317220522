<template>
  <div id="app">
    <keep-alive>
      <router-view />
    </keep-alive>
    <!-- <div @click="update()" style="position:fixed;bottom: 0;right: 60px;width: 60px;height: 40px;color: #000;background: #fff;">刷新页面</div> -->
  </div>
</template>
<script>
export default {
  mounted() {
    this.init()
  },
  methods: {
    init() {
      //横屏展示
      this.forceLandscapeScreenHandle();
      this.onWindowSizeChanged();
    },
    forceLandscapeScreenHandle() {
      const body = document.getElementsByTagName('body')[0];
      const html = document.getElementsByTagName('html')[0];
      const width = html.clientWidth;
      const height = html.clientHeight;
      const max = width > height ? width : height;
      const min = width > height ? height : width;
      body.style.width = max + "px";
      body.style.height = min + "px";
    },
    onWindowSizeChanged() {
      window.addEventListener("resize", this.forceLandscapeScreenHandle)
    },
    update() {
      location.reload()
    }
  }
};
</script>
<style lang="scss">
#app {
  /*文字居中*/
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // text-align: center;
  height: 100%;
  width: 100%;
}

* {
  /*初始化样式*/
  margin: 0;
  padding: 0;
}

html {
  /*用于 获取 屏幕的可视宽高*/
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  /*让 body 初始 width 和 height 就 等于 页面可视区域的 宽高*/
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

}

@media screen and (orientation: portrait) {

  /*竖屏样式*/
  body {
    transform-origin: 0 0;
    transform: rotateZ(90deg) translateY(-100%);
  }
}
</style>
