import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('@/views/common/login.vue'),
  //   meta: {
  //     noLogin: true,
  //   },
  // },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/common/404.vue'),
    meta: {
      noLogin: true,
    },
  },
  {
    path: '/_main',
    component: () => import('@/views/main-layout.vue'),
    name: 'main',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/modules/home/home.vue'),
        meta: {
          key: 'home',
          isTopBar: false,
          isTabBar: true,
          isKeepAlive:false
        }
      },
      {
        path: '/book-in',
        name: 'book-in',
        component: () => import('@/views/modules/book-in/book-in.vue'),
        meta: {
          key: 'book-in',
          isKeepAlive:false
        }
      },
      {
        path: '/self-check-in',
        name: 'self-check-in',
        component: () => import('@/views/modules/self-check-in/self-check-in.vue'),
        meta: {
          key: 'self-check-in',
          isKeepAlive:false
        }
      },
      {
        path: '/renewal',
        name: 'renewal',
        component: () => import('@/views/modules/renewal/renewal.vue'),
        meta: {
          key: 'renewal',
          isKeepAlive:false
        }
      },
      {
        path: '/check-out',
        name: 'check-out',
        component: () => import('@/views/modules/check-out/check-out.vue'),
        meta: {
          key: 'check-out',
          isKeepAlive:false
        }
      },
      {
        path: '/check-in-detail',
        name: 'check-in-detail',
        component: () => import('@/views/modules/check-in-detail/check-in-detail.vue'),
      },
      {
        path: '/demo',
        name: 'demo',
        component: () => import('@/views/modules/demo/demo.vue'),
      },
    ]
  },
  {
    path: '*',
    redirect: '/home'
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // if (!to.meta.noLogin) {
  //   let userInfo = VueCookies.get("userInfo");
  //   if (!userInfo) {
  //     next({ path: "/home" });
  //     return;
  //   }
  // }
  next()
});

export default router