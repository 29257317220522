import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    platform: "android", // 平台----android-安卓/windows-微软
    currentUserInfo: {},//刷身份证当前用户的信息
    isVip: null,//是否是会员
    vipInfo: {},//只有是会员才有会员信息
  },
  mutations: {
    setStateData(state, data) {
      state[data.key] = data.value;
    },
    setCurrentUserInfo(state, data) {
      console.log("ss", data)
      state.currentUserInfo = data || {}
    },
    clearCurrentUserInfo(state) {
      state.currentUserInfo = {}
    },
    clearAllInfo(state) {
      console.log("111")
      state.currentUserInfo = {};
      state.isVip = null;
      state.vipInfo = {};
    }
  },
  actions: {
  },
  modules: {
  }
})
